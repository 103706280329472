import React, { Children, useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridSelectionModel,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import FilterSelectDropdownField from "../filter/filterselectdropdown";
import FilterTextField from "../filter/filtertextfield";
import { makeStyles } from "@material-ui/core";
import {
  Archiveicon,
  Modalcloseicon,
  Sendicon,
  Tableediticon,
  UnArchiveicon,
  Workinprogressicon,
} from "../../assets/svgicons";
import PermissionGateway from "../permission/PermissionGateway";
import { getPermissionAccess } from "../../util/getPermissionAccess";
import { CustomPagination } from "./custompagination";
import { element } from "prop-types";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { borderBottom, height, minHeight, padding, width } from "@mui/system";

const useStyles = makeStyles({
  datagrid1: {
    width: "100%",

    "& .MuiDataGrid-virtualScroller": {
      overflow: "auto !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "fit-content !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "0.75rem !important",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      fontSize: "0.75rem !important",
      fontWeight: "700 !important",
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#4D5565",
        fontSize: "0.82rem !important",
        fontWeight: "500 !important",
      },
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
      fontSize: "0.75rem !important",
      fontWeight: "500 !important",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: "1px solid #f0f0f0",
    },
    "& .MuiDataGrid-cell": {
      color: "rgba(77, 85, 101, 0.9)",
      "&:focus": {
        outline: "none !important",
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: "1px solid #f0f0f0",
    },
  },

  mobiledatgrid1: {
    width: "100%",
    // minHeight: "75vh !important",
    "& .MuiDataGrid-virtualScroller": {
      overflow: "auto !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "fit-content !important",
    },
    "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
      borderBottom: "1px solid #e0e0e0 !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "0.75rem !important",
    },
    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
      width: "100% !important",
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      fontSize: "0.75rem !important",
      fontWeight: "700 !important",
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#4D5565",
        fontSize: "0.82rem !important",
        fontWeight: "500 !important",
        width: "100% !important",
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      width: "100% !important",
    },
    "& .MuiDataGrid-columnHeader": {
      padding: "0px !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
      fontSize: "0.75rem !important",
      fontWeight: "500 !important",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: "1px solid #f0f0f0",
    },
    "& .MuiDataGrid-cell": {
      color: "rgba(77, 85, 101, 0.9)",
      padding: "0px 0px !important",
      "&:focus": {
        outline: "none !important",
      },
      "&:focus-within": {
        outline: "none !important",
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: "1px solid #f0f0f0",
    },
  },
});

function ServerDatagrid({
  tableData,
  tableConfig,
  checkboxSelection = false,
  tabAddbtn,
  sideBtns,
  setSelectRows,
  selectedRows,
  getRowHeight = undefined,
  getRowClassName = "",
  datagridHeight = "75vh",
  scrollHeightMobileView = "560px",
}) {
  const classes = useStyles();

  const style = {
    position: "absolute",
    // top: "50%",
    left: "50%",
    transform: "translate(-50%, 50px)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 2,
  };

  const [listingData, setListingData] = useState({
    data: [],
    totalRecords: 0,
    loading: true,
  });

  //responsive filter icon
  const [isFilterModalOpen, setListFilterModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:991px)");
  const isMobileDataGrid = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    if (!isMobile) {
      setListFilterModalOpen(false);
    }
  }, [isMobile]);

  const type = "server";

  let isArray = Array.isArray(sideBtns?.props?.children);
  let check = false;
  if (isArray) {
    check = sideBtns?.props?.children.every((element) => element === false);
  }

  useEffect(() => {
    setListingData({ ...tableData, defaultrows: tableConfig.rows });
  }, [tableData]);

  const handleClose = () => {
    setListFilterModalOpen(false);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {tableConfig.filters && (
          <Grid
            container
            mb={2}
            justifyContent={isMobile && "flex-end"}
            spacing={1}
          >
            <Grid item sm={12} md={12}>
              <Stack
                direction="row"
                justifyContent={isMobile ? "flex-end" : "flex-start"}
                alignItems="center"
                spacing={1}
              >
                {isMobile == false &&
                  tableConfig.filters.map((filter) => {
                    let useFlex;
                    useFlex = tableConfig.filters.length > 2 ? true : false;
                    if (filter.type == "text") {
                      return (
                        <Box sx={useFlex ? { flex: 1 } : { minWidth: 250 }}>
                          <FilterTextField
                            placeholderLabel={filter.filterText}
                            onChangeFunction={filter.filterfunction}
                            value={filter.value}
                            name={filter.filter}
                          />
                        </Box>
                      );
                    }
                    if (filter.type == "select") {
                      return (
                        <Box sx={useFlex ? { flex: 1 } : { minWidth: 250 }}>
                          <FilterSelectDropdownField
                            placeholderLabel={filter.filterText}
                            options={filter.data}
                            columnSelector={filter.columnSelector}
                            columnIndex={filter.columnIndex}
                            onChangeFunction={(event, value) =>
                              filter.filterfunction(
                                event,
                                value,
                                filter.filter,
                                filter.columnIndex
                              )
                            }
                            value={filter.value}
                          />
                        </Box>
                      );
                    }
                  })}

                {isMobile ? (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={
                      Children.count(tabAddbtn?.props?.children) > 0 && 1
                    }
                  >
                    {Children.count(sideBtns?.props?.children) > 0 &&
                      !check && (
                        <Box
                          sx={
                            tableConfig.filters.length <= 2 && {
                              marginLeft: "auto !important",
                            }
                          }
                        >
                          {sideBtns}
                        </Box>
                      )}
                    {Children.count(tabAddbtn?.props?.children) > 0 && (
                      <Box
                        sx={
                          tableConfig.filters.length <= 2 && {
                            marginLeft: "auto !important",
                          }
                        }
                      >
                        {tabAddbtn}
                      </Box>
                    )}

                    <Box onClick={() => setListFilterModalOpen(true)}>
                      <Tooltip title="Filter">
                        <FilterAltOutlinedIcon sx={{ color: "grey" }} />
                      </Tooltip>
                    </Box>
                  </Stack>
                ) : (
                  <>
                    {Children.count(sideBtns?.props?.children) > 0 &&
                      !check && (
                        <Box
                          sx={
                            tableConfig.filters.length <= 2 && {
                              marginLeft: "auto !important",
                            }
                          }
                        >
                          {sideBtns}
                        </Box>
                      )}
                    {Children.count(tabAddbtn?.props?.children) > 0 && (
                      <Box
                        sx={
                          tableConfig.filters.length <= 2 && {
                            marginLeft: "auto !important",
                          }
                        }
                      >
                        {tabAddbtn}
                      </Box>
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
        <DataGrid
          rows={listingData.data}
          autoHeight
          checkboxSelection={checkboxSelection}
          selectionModel={selectedRows && Array.from(selectedRows)}
          onSelectionModelChange={(e) => {
            const selectedIDs = new Set(e);
            setSelectRows(selectedIDs);
          }}
          headerHeight={35}
          getRowId={(row) => row.id}
          getRowHeight={getRowHeight}
          columns={tableConfig.columns}
          className={
            !isMobileDataGrid ? classes.datagrid1 : classes.mobiledatgrid1
          }
          pageSize={tableConfig.rows}
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPageSizeChange={tableConfig.onPageSizeChange}
          paginationMode="server"
          page={tableConfig.pageno}
          rowCount={listingData.totalRecords}
          onPageChange={tableConfig.onPageChange}
          loading={listingData.loading}
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          disableColumnFilter={true}
          getRowClassName={getRowClassName}
          disableColumnSelector={true}
          components={{
            Footer: CustomPagination,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {tableConfig?.module === "deliverable"
                  ? "Congratulations!!! There are no " +
                    tableConfig?.deliverableType +
                    " tasks"
                  : "No Data Available"}
              </Stack>
            ),

            // NoResultsOverlay: () => (
            //   <Stack height="100%" alignItems="center" justifyContent="center">
            //     No Data Available
            //   </Stack>
            // ),
          }}
          componentsProps={{
            footer: { listingData, type },
          }}
          sx={{
            minHeight: isMobileDataGrid ? datagridHeight : "",
            "& .MuiDataGrid-virtualScrollerContent": {
              minHeight: isMobileDataGrid
                ? `${scrollHeightMobileView} !important`
                : "",
            },
          }}
        />
      </div>

      {isMobile && (
        <Modal
          open={isFilterModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Box sx={style} className="taskmodal">
              <Box
                className={"pointer pull-right"}
                onClick={() => handleClose()}
              >
                <Modalcloseicon width={18} />
              </Box>

              <p className="title">Filters</p>
              <Divider sx={{ mt: 1, mb: 1 }} />

              <Grid container mb={1} spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack
                    direction="column"
                    justifyContent={"center"}
                    alignItems="center"
                    spacing={2}
                  >
                    {tableConfig.filters.map((filter) => {
                      if (filter.type == "text") {
                        return (
                          <Box width={"100%"}>
                            <FilterTextField
                              placeholderLabel={filter.filterText}
                              onChangeFunction={filter.filterfunction}
                              value={filter.value}
                              name={filter.filter}
                            />
                          </Box>
                        );
                      }
                      if (filter.type == "select") {
                        return (
                          <Box width={"100%"}>
                            <FilterSelectDropdownField
                              placeholderLabel={filter.filterText}
                              options={filter.data}
                              columnSelector={filter.columnSelector}
                              columnIndex={filter.columnIndex}
                              onChangeFunction={(event, value) =>
                                filter.filterfunction(
                                  event,
                                  value,
                                  filter.filter,
                                  filter.columnIndex
                                )
                              }
                              value={filter.value}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                mt={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1.5}
              >
                <Button
                  variant="contained"
                  className={"btn-info"}
                  size="medium"
                  onClick={() => handleClose()}
                >
                  Ok
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default ServerDatagrid;
