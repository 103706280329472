// Libraries
import { useState, useEffect } from 'react'

// MUI Library
import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  textfeild: {

    "& .MuiFormLabel-root": {
      fontSize: '12px',
      left: '2px',
      lineHeight: '1.3 !important',
      maxWidth: '100% !important'
    },
    "& .MuiInputLabel-shrink": {
      lineHeight: '1.2 !important',
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      fontSize: 'inherit',
      left: '0px',
    },
    "& .MuiInputBase-root": {
      height: '34px',
      padding: '5px !important',
      "& input": {
        fontSize: '12px !important',
        padding: '5px !important'
      }

    }

  },
});

const FilterSelectDropdownField = ({ placeholderLabel, options, columnSelector, columnIndex, onChangeFunction, value }) => {
  const classes = useStyles();
  const [fieldValue, setFieldValue] = useState(null);
  useEffect(() => {
    if (options.length > 0) {
      let temp = options.find((option) => option[columnIndex] === value)
      if (temp != undefined) {
        setFieldValue(
          options.find((option) => option[columnIndex] === value)
        )
      } else {
        setFieldValue(null)
      }
    }
  }, [options, value]);

  return (
    <>
      <Autocomplete
        id="combo-box-demo"
        size="small"
        // style={{padding:"0px",position:"relative"}}
        disablePortal
        // style={{height:"10px",position:"relative"}}
        // loading
        className={classes.textfeild}
        getOptionLabel={(option) => option[columnSelector]}
        options={options}
        value={fieldValue}

        noOptionsText={'No options'}

        onChange={onChangeFunction}
        disabled={!options.length}

        renderInput={(params) =>
          <TextField
            // sx={{position:"absolute",top:"-15px"}}
            {...params}

            label={placeholderLabel}
          />
        }
      />
    </>
  )
}
export default FilterSelectDropdownField